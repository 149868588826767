@import "src/scss/module";

.ParallaxBackground {
  position: absolute;
  z-index: -1;
  pointer-events: none;

  img {
    max-width: 150%;
  }

  &[data-floating="true"] {
    img {
      animation-duration: 20s;
      animation-iteration-count: infinite;
      animation-timing-function: ease-in-out;
    }

    @for $i from 1 through 4 {
      &:nth-of-type(#{$i}) {
        img {
          animation-name: animate#{$i};
        }
      }
    }
  }
}

@for $i from 1 through 4 {
  @keyframes animate#{$i} {
    0% {
    }
    25% {
      transform: translate((random(30) + px), (random(30) + px));
    }
    50% {
      transform: translate((random(30) + px), (random(30) + px));
    }
    75% {
      transform: translate((random(30) + px), (random(30) + px));
    }
    100% {
    }
  }
}
